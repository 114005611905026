<template>
  <div>
    <template v-if="numPages">
      <vue-pdf-up
          v-for="i in numPages"
          :key="i"
          :src="source"
          :page="i"
      ></vue-pdf-up>
    </template>
    <template v-else>
      <b-spinner />
    </template>
  </div>
</template>

<script>

import vuePdfUp from 'vue-pdf-up'
export default {
  components: {
    vuePdfUp
  },
  props: ['source'],
  data() {
    return {
      numPages: null,
    }
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        vuePdfUp.createLoadingTask(this.source).promise.then(pdf => {
          this.numPages = pdf.numPages
          console.log('alert')
        })
      });
    }, 1000)
  }
}

</script>