<template>
  <div class="wrapper">
    <head-component/>
    <section class="block-study" v-if="course">
      <h1 class="mb-4">{{ course.name }}</h1>
      <h5 class="mb-4">{{ course.shortDescription }}</h5>
      <div v-for="module in course.modules" :key="module.id" class="row">
        <div class="col-auto">
          <div class="block-study__list">
            <div @click="setCurrent(key)" v-for="(element, key) in module.moduleElements" :key="element.id" class="block-study__list-item" :class="{active: key === currentKey}">
              <div class="block-study__list-item-title"><span>{{ element.name }}</span></div>
              <div class="block-study__list-item-duration">
                <i :class="`fa fa-${element.type}`"></i>
                {{ element.type_ru }}
              </div>
            </div>
          </div>
        </div>
        <template v-for="(element, key) in module.moduleElements">
          <div v-if="key === currentKey" class="col col-study animated fadeIn" :key="element.id">
              <div class="study-item">
                <h2 class="study-item__title mb-2">{{ element.name }}</h2>
                <test-passage v-if="element.type === 'list'" :course="course.id" :test-id="element.test.id"/>
                <div v-else-if="element.type === 'file-pdf-o'" class="element-wrapper">
                  <div v-if="element.description && element.description !== '<p><br></p>'">
                    <b-tabs content-class="mt-3">
                      <b-tab title="Задание" active v-html="element.description"></b-tab>
                      <b-tab title="Рабочая тетрадь">
                        <div class="text-center" style="margin: 20px 0;">
                          <a class="btn btn-success" target="_blank" :href="`https://api.universarium.org/media_files/${element.file.id}`">Скачать</a>
                        </div>
                        <div class="study-item__video d-flex align-items-center justify-content-center study-item__pdf">
                          <pdf-viewer :source="`https://api.universarium.org/media_files/${element.file.id}`" />
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                  <template v-else>
                    <div class="text-center" style="margin: 20px 0;">
                      <a class="btn btn-success" target="_blank" :href="`https://api.universarium.org/media_files/${element.file.id}`">Скачать</a>
                    </div>
                    <div class="study-item__video d-flex align-items-center justify-content-center study-item__pdf">
                      <pdf-viewer :source="`https://api.universarium.org/media_files/${element.file.id}`" />
                    </div>
                  </template>
                </div>
                <div v-else-if="element.type === 'film'" class="study-item__video d-flex align-items-center justify-content-center">
                  <video class="section__front-video" controls controlsList="nodownload">
                    <source :src="element.file.contentPath" type="video/mp4">
                  </video>
                </div>
              </div>
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>

import ApiService from "../services/api.service";
import HeadComponent from "../components/HeadComponent";
import PdfViewer from "../components/PdfViewer";
import TestPassage from "../components/Test/TestPassage";
export default {
  name: "Study",
  components: {
    TestPassage,
    PdfViewer,
    HeadComponent,
    //vuePdfUp
  },
  data() {
    return {
      currentKey: 0,
      form: {
        email: '',
        phone: '',
        name: '',
        lastname: '',
      },
      course: null,
      show: true
    }
  },
  mounted() {
    ApiService.get(`courses/${this.$route.params.id}`).then(res => {
      this.course = res.data
    })
  },
  methods: {
    setCurrent(key) {
      this.currentKey = key
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.lastname = ''
      this.form.phone = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.col-study {
  display: block;
}
.element-wrapper {
  position: relative;
  .pdf-download {
    position: absolute;
    top: 20px;
    left: 20px;
    text-align: center;
  }
}
.section__front-video {
  max-width: 100%;
}
.study-item__pdf > div {
  width: 100%;
  text-align: center;
  min-height: 200px;
  position: relative;
  .spinner-border {
    display: block!important;
    top: 50%!important;
    position: absolute!important;
  }
}
</style>